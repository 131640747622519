.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
}

.slide {
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

/* OTP Timer */
.resendOTPTimer {
  padding: 0;
  margin: 0;
  font-size: 0.7rem;
  color: #676767;
}

.otpWillRecivie,
.resendOTPRequest {
  font-size: 0.7rem;
  color: #676767;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .padding-2rem {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  table td {
    word-break: break-all;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
